<template>
    <b-card v-if="user" no-body >

        <b-card-body style="padding: 24px;" >
            <div class="d-flex flex-column align-items-center">
                <b-avatar
                    :variant="Users.utils.resolveAvatarColor( user.id )"
                    :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                    size="44px"
                />
                <div class="d-flex mt-1 text-center">
                    <div>
                      <h5 class="mb-0">
                        {{ user.firstName }} {{ user.lastName }}
                      </h5>
                      <div  style="font-size: 12px; opacity: 0.55;" >
                            {{ Users.utils.resolveUserGroupName(user.group) }}
                      </div>
                    </div>
                </div>
            </div>
                              
            <p style="margin-bottom: 6px; margin-top: 30px"> Заработано <b-link @click="changeCurrency">в {{ User.currency }}</b-link> </p>

            <div class="d-flex">
                <p style="font-size: 28px; font-weight: 600;">{{ total_profit.formatMoney( User.currency === 'BTC' ? 6 : 2,' ', ' ') }} {{ User.getSymbol() }}</p>
            </div>

            <div style="margin-top: 6px;">
                <span class="text-muted font-weight-light">
                    Пришло по ссылке
                </span>
                <p style="font-size: 22px; font-weight: 500; margin-top: 4px;">
                    {{ user.referral.invitations.length }} 
                </p>
            </div>
            
        </b-card-body>

        
        <div style="padding: 0 24px 24px 24px;">
            
            <div class="d-flex flex-column-reverse justify-content-between" >
                <span class=" font-small-2" style="opacity: 0.55;">
                    Статус реферальной ссылки
                </span>
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <b-card-text v-if="user.is_referral" class="m-0 ">Активна</b-card-text>
                    <b-card-text v-else class="text-danger m-0">Не активна</b-card-text>

                    <b-form-checkbox
                        v-model="user.is_referral"
                        class="mr-0 mb-50"
                        name="referral-checkbox"
                        switch
                        inline
                        @change="update(true, true)"
                    />

                </div>
            </div>
            <template>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
                <div  class="d-flex justify-content-between" style="align-items: center;  margin: 6px 0;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Кэшбэк партнера:
                    </span>
                </div>
            </template>
            <template>
                <!-- <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div> -->
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 12-23 месяца (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitation_percent_12_23" size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 24-35 месяцев (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitation_percent_24_35" size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 36 месяцев (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitation_percent_36" size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center; margin-top: 1px" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        С покупки оборудования (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitation_percent_m" size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
                <div  class="d-flex justify-content-between" style="align-items: center;  margin: 6px 0;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Бонус рефералу на первую аренду облака (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitee_percent" size="sm" style="border-radius: 100px; width: 50px; text-align: center;" />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Скидка рефералу на первую покупку оборудования (%)
                    </span>
                    <b-form-input @change="update" type="number" placeholder="%" v-model="user.referral.invitee_percent_m" size="sm" style="border-radius: 100px; width: 50px; text-align: center;" />
                </div>
            </template>

            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
            <div v-if="user.referral.ref !== null" class="d-flex flex-column-reverse justify-content-between overflow-hidden" >
                <div class="d-flex justify-content-between"> 
                        <div ref="container" class="wordwrap">
                           <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                                Ссылка
                            </span>
                        </div>
                        <div v-ripple @click="copyUrl" style="margin: auto 12px; margin-right: 0;">
                            <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                        </div>
                </div>
                <b-link :href="generate_url" class="text-ellipsis ">{{start_and_end(generate_url)}}</b-link>
            </div>
            <div v-else class="d-flex flex-column-reverse justify-content-between overflow-hidden" >
                <div class="d-flex justify-content-between"> 
                        <div ref="container" class="wordwrap">
                           <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                                Ссылка
                            </span>
                        </div>
                </div>
                <span> - </span>
            </div>
            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
           
            
        </div>  
    
    </b-card>

    <b-card v-else no-body >
          <b-card-body style="padding: 24px;" >
            <div class="d-flex flex-column align-items-center">
                <div class="d-flex mt-1 text-center">
                    <div>
                      <h5 class="mb-0">
                        Выберите пользователя
                      </h5>
                    </div>
                </div>
            </div>
                              
            <p style="margin-bottom: 6px; margin-top: 30px"> Заработано </p>

            <div class="d-flex">
                <p style="font-size: 28px; font-weight: 600;">0</p>
            </div>

            <div style="margin-top: 6px;">
                <span class="text-muted font-weight-light">
                    Пришло по ссылке
                </span>
                <p style="font-size: 22px; font-weight: 500; margin-top: 4px;">
                    0
                </p>
            </div>
            
        </b-card-body>

        
        <div style="padding: 0 24px 24px 24px;">
            
            <div class="d-flex flex-column-reverse justify-content-between" >
                <span class=" font-small-2" style="opacity: 0.55;">
                    Статус реферальной ссылки
                </span>
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                  -

                </div>
            </div>


             <template>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
                <div  class="d-flex justify-content-between" style="align-items: center;  margin: 6px 0;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Кэшбэк партнера:
                    </span>
                </div>
            </template>
             <template>
                <!-- <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div> -->
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 12-23 месяца (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 24-35 месяцев (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Контракт периодом 36 месяцев (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center; margin-top: 1px" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        С покупки оборудования (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;"  />
                </div>
            </template>

            <template>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
                <div  class="d-flex justify-content-between" style="align-items: center;  margin: 6px 0;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Бонус рефералу на первую аренду облака (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;" />
                </div>
            </template>

            <template>
                <div  class="d-flex justify-content-between" style="align-items: center;" >
                    <span class=" font-small-2" style="opacity: 0.55;">
                        Скидка рефералу на первую покупку оборудования (%)
                    </span>
                    <b-form-input type="number" placeholder="%" disabled size="sm" style="border-radius: 100px; width: 50px; text-align: center;" />
                </div>
            </template>
            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
            <div class="d-flex flex-column-reverse justify-content-between overflow-hidden" >
                <div class="d-flex justify-content-between"> 
                        <div ref="container" class="wordwrap">
                           <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                                Ссылка
                            </span>
                        </div>
                </div>
                <span> - </span>
            </div>
            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
           
            
        </div>  
    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    import Users from "@/modules/admin/users/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    
    export default {

        data() {
            return {
                User,
                Users,
                Blockchain,

            }
        },
        props: {
            user: Object,
        },
        methods: {

            update(reload = false, update_active = false) {

                if (this.user.referral.invitee_percent > 100) this.user.referral.invitee_percent = 99
                if (this.user.referral.invitee_percent < 0) this.user.referral.invitee_percent = 0

                if (this.user.referral.invitee_percent_m > 100) this.user.referral.invitee_percent_m = 99
                if (this.user.referral.invitee_percent_m < 0) this.user.referral.invitee_percent_m = 0

           
                if (this.user.referral.invitation_percent_12_23 > 100) this.user.referral.invitation_percent_12_23 = 99
                if (this.user.referral.invitation_percent_12_23 < 0) this.user.referral.invitation_percent_12_23 = 0

                if (this.user.referral.invitation_percent_24_35 > 100) this.user.referral.invitation_percent_24_35 = 99
                if (this.user.referral.invitation_percent_24_35 < 0) this.user.referral.invitation_percent_24_35 = 0

                if (this.user.referral.invitation_percent_36 > 100) this.user.referral.invitation_percent_36 = 99
                if (this.user.referral.invitation_percent_36 < 0) this.user.referral.invitation_percent_36 = 0

                if (this.user.referral.invitation_percent_m > 100) this.user.referral.invitation_percent_m = 99
                if (this.user.referral.invitation_percent_m < 0) this.user.referral.invitation_percent_m = 0
                

                this.$request.post("referral/update", {
                    invitee_percent: this.user.referral.invitee_percent,
                    invitee_percent_m: this.user.referral.invitee_percent_m,

                    invitation_percent_12_23: this.user.referral.invitation_percent_12_23,
                    invitation_percent_24_35: this.user.referral.invitation_percent_24_35,
                    invitation_percent_36: this.user.referral.invitation_percent_36,
                    invitation_percent_m: this.user.referral.invitation_percent_m,

                    is_referral: this.user.is_referral,
                    update_active: update_active,
                    user: this.user.id
                }).then( rsp => {
                    if (reload) {
                        this.$root.$emit("reloadReferralUsers");
                    }
                });
            },

            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },

            start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

            copyUrl( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.generate_url, container );
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Скопировано.',
                      text: 'Ссылка успешно скопирована в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },
        },
        
        computed: {
            
            ticker() {
               return User.getSymbol();
            },
            
            total_profit() {
                
                if (this.user) {
                    return (this.user.referral.balance) * Blockchain.tickers.values[User.currency].last;     
                }
               
            },

            generate_url() {

                if (this.user.referral.ref !== null) {
                    return process.env.VUE_APP_BASE_URL + 'register/?ref=' + this.user.referral.ref 
                } 
                
                return ''

            }
           
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>