<template>
    <div>
    <b-card no-body v-if="!is_show_invitee">
        <b-card-header>
            <b-card-title>
                Пользователи реферальной программы
            </b-card-title>

            <b-card-title style="display: flex">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    :to="{ name: 'admin-users-list' }"
                    style="height: 2.142rem; font-size: 12px; line-height: 0.5; margin: 0px 20px; border-radius: 100px;"
                >
                    Добавить к программе
                </b-button>
                <b-form-input  type="text" placeholder="Поиск" v-model="searchUsers" size="sm" style="border-radius: 100px;"  />
            </b-card-title>
        </b-card-header>
        <b-skeleton-table v-if='dataSource.items.length === 0' animation="fade" :columns='4' :rows='5'>
            
        </b-skeleton-table>
        <b-table-simple v-else responsive>
            <b-thead>
                <b-tr>
                    <b-th class="text-nowrap">Пользователь</b-th>
                    <b-th class="text-nowrap">Почта</b-th>
                    <b-th>Статус реферальной ссылки</b-th>
                    <b-th>Клиентов привел</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="user in filterDataSource" :key="user.id" v-ripple @click="showCard(user)" class=" font-weight-normal cursor-pointer">
                    <b-th class="font-weight-normal text-nowrap">
                        <div class="d-flex">
                            <b-avatar
                                :variant="Users.utils.resolveAvatarColor( user.id )"
                                :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                                @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})"
                            />
                            <div class="d-flex flex-column" style="margin-left: 12px;"  @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})">
                                <span class="font-weight-bolder text-primary text-capitalize m-0">
                                    {{ user.firstName }} {{ user.lastName }}
                                </span>
                                <span class="text-muted" style="font-size: 11px;">#{{ user.id }}</span>
                            </div>
                        </div>
                    </b-th>
                    <b-th class="font-weight-normal">
                        {{ user.email }}
                    </b-th>
                 
                    <b-th>
                        <b-badge v-if="user.is_referral" variant="light-success">
                            Ссылка активна
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            Ссылка не активна
                        </b-badge>
                    </b-th>

                    <b-th >
                       <span class="text-primary" @click="showInviteeUsers(user.referral.id)">{{ user.referral.invitations.length }}</span>
                    </b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        </b-card>

        <b-card no-body v-else>
            <b-card-header>
                <b-card-title>
                    <div @click="cleanInviteeUsers" class="d-flex" style="cursor: pointer">
                        <div class="q-icon icon-chevron-left" style="background-size: 17px 17px;"></div>
                        <span class="text-muted mt-auto mb-auto" style="margin-left: 5px; font-weight: 300; font-size: 12px;">К предыдущему списку</span>
                     </div>
                    Пользователи зарегистрированные по реферальной ссылке 
                </b-card-title>

                <b-card-title>
                    <b-form-input  type="text" placeholder="Поиск" v-model="searchInvitee" size="sm" style="border-radius: 100px;"  />
                </b-card-title>
            </b-card-header>
            <b-skeleton-table v-if='dataSource.items.length === 0' animation="fade" :columns='4' :rows='5'>
                
            </b-skeleton-table>
            <b-table-simple v-else responsive>
                <b-thead>
                    <b-tr>
                        <b-th class="text-nowrap">Пользователь</b-th>
                        <b-th class="text-nowrap">Почта</b-th>
                        <b-th>Зарегистривался по ссылке пользователя</b-th>
                        <b-th>Статус скидки за регистрацию</b-th>
                        <b-th>Заработал {{ selected.firstName }} {{ selected.lastName }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="user in filterInviteeUsers" :key="user.id" class=" font-weight-normal cursor-pointer">
                        <b-th class="font-weight-normal text-nowrap">
                            <div class="d-flex">
                                <b-avatar
                                    :variant="Users.utils.resolveAvatarColor( user.id )"
                                    :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                                    @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})"
                                />
                                <div class="d-flex flex-column" style="margin-left: 12px;"  @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})">
                                    <span class="font-weight-bolder text-primary text-capitalize m-0">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </span>
                                    <span class="text-muted" style="font-size: 11px;">#{{ user.id }}</span>
                                </div>
                            </div>
                        </b-th>
                        <b-th class="font-weight-normal">
                            {{ user.email }}
                        </b-th>
                    
                        <b-th>
                            <div class="d-flex">
                                <!-- <b-avatar
                                    :variant="Users.utils.resolveAvatarColor( user.id )"
                                    :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                                    @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})"
                                /> -->
                                <div class="d-flex flex-column" style="margin-left: 12px;" >
                                    <span class=" text-capitalize m-0 font-weight-normal">
                                        {{ selected.firstName }} {{ selected.lastName }}
                                    </span>
                                    <span class="text-muted" style="font-size: 11px;">#{{ selected.id }}</span>
                                </div>
                            </div>
                            <!-- <b-badge v-if="user.is_referral" variant="light-success">
                                Ссылка активна
                            </b-badge>
                            <b-badge v-else variant="light-danger">
                                Ссылка не активна
                            </b-badge> -->
                        </b-th>

                        <b-th >
                            <b-badge v-if="!user.invitee.is_discount_used" variant="light-success">
                                Не использовано
                            </b-badge>
                            <b-badge v-else variant="light-danger">
                                Использовано
                            </b-badge>
                        </b-th>

                        <b-th class="font-weight-normal">
                            <span v-if="user.reftransactions">
                                {{ user.reftransactions.amount }} ₿
                            </span>
                            <span v-else>
                                -
                            </span>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>
    </div>
    
</template>

<script>
    
    import Users from "@/modules/admin/users/"
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                Users,
                dataSource: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },  
                
                searchUsers: '',
                searchInvitee: '',
                selected: null,

                inviteeUsers: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },
                is_show_invitee: false
                
            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'users' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {
            get(update_selected = false) {
                const t = this

                this.$request.get("referral/users", {
                    page: this.$route.query.page
                }).then( rsp => {
                    Object.keys( this.dataSource ).forEach( key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.dataSource[key] = rsp[key];
                        }
                    });

                    if (update_selected) {
                        if (t.selected !== null ) {
                            t.showCard(t.dataSource.items.find(user => user.id == t.selected.id))
                        }
                    }
                });
            },

            showCard(user) {
                this.selected = user
                this.$emit('showCard', user)
            },

            showInviteeUsers(referral) {

                this.$route.query.page = 1
                this.is_show_invitee = true

                this.$request.get("referral/invitee.users", {
                    page: this.$route.query.page,
                    id: referral
                }).then( rsp => {
                    Object.keys( this.inviteeUsers ).forEach( key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.inviteeUsers[key] = rsp[key];
                        }
                    });
                });
            },

            cleanInviteeUsers() {

                this.is_show_invitee = false
                this.inviteeUsers = {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                }

            }
        },
        computed: {
            filterDataSource() {
                return this.dataSource.items.filter(post => {
                    return (
                        (post.lastName !== null && post.lastName.toLowerCase().includes(this.searchUsers.toLowerCase())) ||
                        (post.firstName !== null && post.firstName.toLowerCase().includes(this.searchUsers.toLowerCase())) ||
                        post.email.toLowerCase().includes(this.searchUsers.toLowerCase())
                    )
                })
            },

            filterInviteeUsers() {
                return this.inviteeUsers.items.filter(post => {
                    return (
                        (post.lastName !== null && post.lastName.toLowerCase().includes(this.searchInvitee.toLowerCase())) ||
                        (post.firstName !== null && post.firstName.toLowerCase().includes(this.searchInvitee.toLowerCase())) ||
                        post.email.toLowerCase().includes(this.searchInvitee.toLowerCase())
                    )
                })
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {
            this.get();

            this.$root.$on('reloadReferralUsers', () => {
                this.get(true)
            });
        }

    }

</script>