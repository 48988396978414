<template>
    <div>
          <div class="d-flex">
            <div  style="width: 272px;">
                <div style="position: sticky; top: 16px;">
                    <user-ref-card-view :user="user"/>
                </div>
            </div>
            <div style="width: calc(100% - 272px); padding-left: 32px;">
                <users-list-view ref="ref-users" @showCard="showCard"/>
            </div>
        </div>
     
    </div>
</template>

<script>

    import UserRefCardView from "./widgets/UserRefCardView"
    import UsersListView from "./widgets/UsersListView"

    export default {

        data() {
            return {
                user: null,

            }
        },
        methods: {
            closeModal( action ) {
                    
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                    
            },
                
            showModal( action ) {
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            afterTransactionSend( args ) {
                this.update();
            },

            update() {
                    
                this.$request.get("wallet.get").then( wallet => {
                    User.self.balance = wallet.contracts.balance;
                    User.self.wallet = wallet.general;
                });
                    
            },

            updateWalletType(value) {
                this.activeWalletType = value
                this.$refs.transactions.changeWallet( value );
            },

            showCard( user ) {
                this.user = user
            }
        },
        components: {
            UserRefCardView,
          
            UsersListView
        },
        watch:{

        },
        mounted() {
        }
    }

</script>